import '@fancyapps/ui/dist/fancybox/fancybox.css';
import './stylesheets/casabella'

import Rails from "@rails/ujs";
import "./src/channels";

import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks.min.js';

import './src/casabella/main';

Rails.start();
