import $ from 'jquery';
import * as bootstrap from 'bootstrap';

import '../custom/fonts';
import '../custom/bootstrap';
import enableBootstrapTooltips from '../custom/bootstrap/tooltips';
import * as IC from '../custom/in_control';
import 'select2';
import { Fancybox } from '@fancyapps/ui';

// CASABELLA JAPAN initializations
function initializeSelect2() {
  $('.with-select2').each(function(fi, field) {
    field = $(field);
    let options = {
      width:    'resolve',
      theme:    'bootstrap-5',
      language: {
        noResults: function() {
          return '該当する選択肢がありません';
        }
      }
    };
    if(field.is('.with-tags'))
      options.tags = true;
    field.select2(options);
  });
}

function initializeFancybox() {
  if(!$('.article-body').length)
    return;

  // Wait until all images loaded to initialize because
  // PICTURE elements appear to take longer to load, compared to IMG
  // (see https://kontext.tech/article/807/trigger-event-after-all-images-loaded)
  let images = document.querySelectorAll('.article-body [data-lightbox="fancybox"] [data-fancybox] picture img');

  Promise.all(
    Array.from(images)
      .filter(img => !img.complete)
      .map(img => new Promise(resolve => {
        img.addEventListener('load', resolve);
        img.addEventListener('error', resolve);
      }))
  ).then(() => {
    $('.article-body [data-lightbox="fancybox"] [data-fancybox]').each(function(li, link) {
      let picture = $(link).find('picture');
      if(!picture.length)
        return;

      link.href = picture.find('img')[0].currentSrc;

      let raw = $(link).find('figcaption').html();
      if(typeof(raw) != 'string')
        return;

      let caption = raw.trim();
      if(!caption.length)
        return;

      link.dataset.caption = caption;
    });

    const defaultOptions = {
      tpl: {
        main: `<div class="fancybox__container has-sidebar" role="dialog" aria-modal="true" aria-label="{{MODAL}}" tabindex="-1">
    <div class="fancybox__backdrop"></div>
    <div class="fancybox__cols">
      <div class="fancybox__col">
        <div class="fancybox__carousel"></div>
        <div class="fancybox__footer"></div>
      </div>
      <div class="fancybox__col">
        <div class="fancybox__data">
        </div>
      </div>
    </div>
  </div>`
      },

      idle: false,
      compact: false,
      dragToClose: false,

      showClass: 'f-fadeIn',
      hideClass: 'f-fadeOut',

      on: {
        "Carousel.selectSlide": (fancybox, eventName) => {
          let page    = fancybox.plugins.Slideshow.instance.carousel.page,
              figure  = fancybox.plugins.Slideshow.instance.carousel.slides[page].triggerEl,
              details = $('.details', figure);
          $('.fancybox__data', fancybox.container).html(details.html());
        }
      },

      Images: {
        zoom: false
      },

      Thumbs: {
        type: 'modern'
      },

      Toolbar: {
        parentEl: (toolbar) => {
          return toolbar.instance.container.querySelector('.fancybox__col');
        },
        items: {
          sidebar: {
            tpl: `<button class="f-button" title="詳細"><i class="fa-regular fa-circle-info"></i></button>`,
            click: (toolbar) => {
              toolbar.instance.container.classList.toggle('has-sidebar');
            },
          },
          prev: {
            tpl: '<button class="f-button" title="前へ" data-fancybox-prev><i class="fa-regular fa-circle-arrow-left"></i></button>',
          },
          next: {
            tpl: '<button class="f-button" title="次へ" data-fancybox-next><i class="fa-regular fa-circle-arrow-right"></i></button>',
          },
          close: {
            tpl: `<button class="f-button" title="閉じる" data-fancybox-close><i class="fa-regular fa-circle-xmark"></i></button>`
          }
        },
        display: {
          left: ['infobar'],
          middle: ['toggleZoom'],
          right: ['close']
        }
      }
    };

    $('.article-body [data-lightbox="fancybox"]').each(function(gi, gallery) {
      gallery = $(gallery);

      let options = { ...defaultOptions };
      if(gallery.is('[data-lightbox-with-details]')) {
        options.Toolbar.display.middle.push('sidebar');
        options.caption = function(fancybox, slide) {
          return `${slide.caption} <div class="fs-smaller text-center">(解説は <i class="fa-regular fa-circle-info"></i> で表示)</div>`;
        }
      }

      Fancybox.bind(gallery.get(0), '[data-fancybox]', options);
    });
  })
}


function initializeBoxPreview() {
  let container = $('#pdf_preview');
  if(!container.length)
    return;

  window.waitFor(function() {
    let preview   = new Box.Preview(),
        pdfFileId = container.data('pdf-id'),
        startPage = container.data('start-page') || 1;

    let fileOptions = {};
    fileOptions[pdfFileId] = {
      startAt: {
        unit: 'pages',
        value: startPage
      }
    };

    preview.show(pdfFileId, container.data('token'), {
      container:   '#pdf_preview',
      logoUrl:     container.data('logo'),
      fileOptions: fileOptions
    });
  }, 'box_preview');
}


function icInitializeBootstrapMain() {
  initializeSelect2();
  initializeFancybox();
  initializeBoxPreview();

  enableBootstrapTooltips();

  $('form button.clear').on('click', function(evt) {
    IC.clearForm($(this).closest('form'));
  })
}

window.icInitializeBootstrapMain = icInitializeBootstrapMain;

window.waitFor(
  function() {
    jQuery(function() {
      IC.icPageInitialize();
    });
  },
  'jquery'
);
